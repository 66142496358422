import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const Reservation = lazy(() => import("../../../pages/public/Reservation"));

const ReservationRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <Reservation />
        </Suspense>
    )
}

export default ReservationRoute;