export const PUBLIC_HOME_PAGE = "/";
export const PUBLIC_PRICE_LIST = "/price-list";
export const PUBLIC_SIGN_IN = "/sign-in";
export const PUBLIC_LEADERBOARDS = "/leaderboards";
export const PUBLIC_CALENDAR = "/calendar";
export const PUBLIC_VOUCHER = "/voucher";
export const PUBLIC_RESERVATION = "/reservation";
export const PUBLIC_GALLERY = "/gallery";
export const PUBLIC_ESHOP_TEST = "/eshop-test";
export const PUBLIC_PATHS = {
    homepage: {
        path: PUBLIC_HOME_PAGE,
        name: "menu.homepage"
    },
    priceList: {
        path: PUBLIC_PRICE_LIST,
        name: "menu.priceList"
    },
    register: {
        path: PUBLIC_SIGN_IN,
        name: "menu.signIn"
    },
    leaderboards: {
        path: PUBLIC_LEADERBOARDS,
        name: "menu.leaderboards"
    },
    calendar: {
        path: PUBLIC_CALENDAR,
        name: "menu.calendar"
    },
    voucher: {
        path: PUBLIC_VOUCHER,
        name: "menu.voucher"
    },
    reservation: {
        path: PUBLIC_RESERVATION,
        name: "menu.reservation"
    },
    gallery: {
        path: PUBLIC_GALLERY,
        name: "menu.gallery"
    }
}
